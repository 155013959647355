.spaprojekt-wrapper {
    width: 70%;
    margin: auto;
}

.img-container img {
    max-width: 100%;
    max-height: 80vh;
    align-self: center;
}

.img-container video {
    max-width: 100%;
    max-height: 80vh;
}

.img-container p {
    max-width: 60%;
    margin-top: 1em;
}

.img-container {
    width: auto;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em 0;
    padding: 1em 0;
}


@media screen and (max-width: 768px){
    .spaprojekt-wrapper {
        width: 90%;
        margin: auto;
    }
    
    .img-container p {
        max-width: 90%;
        margin-top: 0.5em;
        font-size: smaller;
    }
    
}