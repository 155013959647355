/* @tailwind base;
@tailwind components;
@tailwind utilities;  */


.hero-div-spa {
    background-image: url("/public/assets/badar-smaller.webp");
    height: 90vh;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5em;
}

.hero-text {
    width: 100%;
    background-color: rgba(29, 29, 29, 0.747);
    color: #f3eee7;
    text-align: center;
    font-size: x-large;
}

.spapaket-wrapper {
    width: 70%;
    margin: auto;
}

.spapaket-innehåll {
    background-color: rgba(92, 88, 88, 0.116);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    flex-direction: row;
    padding: 1em;
    justify-content: space-around;
    margin-top: 3em;
}
.innehållslista {
    list-style: unset;
    flex-direction: column;
    align-items: start;
}

.bg-dark {
    background-color: rgba(92, 88, 88, 0.116);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-top: 2em;
    padding: 0.5em;
}
.bg-dark-round {
    background-color: rgba(92, 88, 88, 0.116);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    align-content: center;
    padding: 3em;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
}
.right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.top-left {
    padding-right: 1em;
    border-right: solid rgba(0, 0, 0, 0.116);
}

.italic {
    font-style: italic;
    font-weight: lighter;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 0;
}

.intro-spapaket {
    margin-top: 5em;
    display: flex;
    gap: 5em;
    justify-content: space-between;
}

.spapaket-innehåll img {
    max-width: 50%;
    height: auto;
}

.button-center {
    text-align: center;
    margin: 3em;
}

table {
    width: 100%;
    border-collapse: collapse;
}

tr:nth-child(:last-child){
    align-self: flex-end;
}

th, td {
    text-align: start;
    border-bottom: 1px solid #f3eee7;
}

tr:hover {background-color: #f3eee7;}

@media only screen and (max-width: 1200px){

    .hero-text {
        font-size: medium;
    }
    .intro-spapaket {
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-top: 1em;
    }

    .intro-spapaket img {
        max-width: 100%;
        height: auto;
        margin: auto;
    }

    .paketen .bg-dark{
        padding: 0;
    }

    .bg-dark-round {
        margin-top: 0;
    }
    
    .spapaket-innehåll {
        display: flex;
        flex-direction: column;
    }

    .spapaket-innehåll img {
        max-width: 80%;
        margin: auto;
    }

    .spapaket-wrapper {
        width: 90%;
    }
    .top-left {
        border-right: none;
    }

    .bg-dark-round {
        margin-bottom: 1em;
    }
}

@media only screen and (max-width: 1550px)and (min-width: 1201px){
    .hero-text {
        font-size: large;
    }
    .intro-spapaket {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 1em;
    }

    .intro-spapaket img {
        width: auto;
    }

    .paketen .bg-dark{
        padding: 0;
    }

    .bg-dark-round {
        margin-top: 0;
    }

    
    .spapaket-innehåll {
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }

    .spapaket-innehåll img {
        max-height: 70vh;
        margin: auto;
    }

    .spapaket-wrapper {
        width: 70%;
    }
    .top-left {
        border-right: none;
    }
}