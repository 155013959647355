.hero-div-behandling {
    background-image: url(/public/assets/massage-small.webp);
    height: 90vh;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: flex-end;
}

.hero-text {
    width: 100%;
    background-color: rgba(29, 29, 29, 0.747);
    color: #f3eee7;
    text-align: center;
    font-size: x-large;
}

.behandling-wrapper {
    width: 60%;
    margin: auto;
    padding-top: 5em;
}

.behandling_list {
    margin-bottom: 5em;
    justify-content: space-between;
    display: flex;
    padding: 0;
    border-bottom: solid rgba(0, 0, 0, 0.116);
    padding-bottom: 1em;
}

.underline {
    border-bottom: 1px solid;
}

.marked {
    font-weight: bolder;
}

.btn{
    padding: 3em 0;
    color: black;
}

.omLymfKnapp {
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: 5em 0.5;
   margin-bottom: 0;
   color: black;
}

.omLymfKnapp:hover {
    transform: none;
}


@media screen and (max-width: 768px){
    .hero-text {
        width: 100%;
        background-color: rgba(29, 29, 29, 0.747);
        color: #f3eee7;
        text-align: center;
        font-size: small;
    }

    .behandling-wrapper {
        width: 90%;
        margin: auto;
        padding-top: 5em;
    }

    .behandling_list {
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
        border-bottom: solid rgba(0, 0, 0, 0.116);
        padding-bottom: 1em;
    }

    .btn {
        display: flex;
        margin-top: 2;
        overflow: none;
    }

    .omLymfKnapp {
        max-width: 60%;
    }
}


@media only screen and (max-width: 1568px)and (min-width: 769px){

    .hero-text {
        width: 100%;
        background-color: rgba(29, 29, 29, 0.747);
        color: #f3eee7;
        text-align: center;
        font-size: medium;
    }

    .behandling_list {
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
        
        color:  #f3eee7;
        border-bottom: solid rgba(0, 0, 0, 0.116);
        padding-bottom: 1em;
    }

    .behandling_list ul {
        align-items: flex-start;
        color:  #f3eee7;
    }

    .omLymfKnapp {
        max-width: 60%;
    }
}