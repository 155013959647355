
.home-hero {
  background-image: url(/public/assets/winter-small.webp);
  height: 90vh;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: flex-end;

}
  .hero-text {
    width: 100%;
    background-color: rgba(29, 29, 29, 0.747);
    color: #f3eee7;
    text-align: center;
    font-size: x-large;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  
  .home-wrapper {
    width: 80%;
    margin: auto;
  }
  
  .intro-section {
    padding: 100px 0;
    max-width: 90%; 
    display: flex;
    margin: auto;
  }
  
  .welcome {
    flex-basis: 70%;
    align-content: center;
  }
  .buttons {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  button {
    padding: 10px;
    margin-right: 10px;
    background-color: #80b16f ;
    border-radius: 10px;
    border: none;
    
    cursor: pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition: all .2s ease-in-out;  
  }
  
  .card-section {
    width: 100%;
    background-color: rgba(92, 88, 88, 0.116);
    display: flex;
    margin: 3em 0;
    /* transition: all .2s ease-in-out;  */
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  
  button:hover {
    transform: scale(1.2);
  }
   
  .card-text {
    margin: 2em;
  }
  
  .tripple {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: space-between;
    padding: 50px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    
  }
  
  .mini-card {
    flex: 1;
    border: 5px solid rgba(92, 88, 88, 0.116);
    margin: 1em;
    padding: 1em;
  }
  .mini-card button {
    max-width: 100px;
  }

  .tripple img {
    width: 400px;
    height:auto
  }

  a {
    text-decoration: none;
    color: inherit!;
  }

  button a {
    color: inherit!;
  }
  @media only screen and (max-width: 668px){
    .home-wrapper {
      width: 100%;
      margin: auto;
    }

    .intro-section {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 1em;
    }

    .welcome {
      justify-content: center;
      align-content: center;
    }

    .card-section {
      display: flex;
      flex-direction: column;
    }
    .card-section img {
      max-width: 100%;
      height: auto;
      
    }

    .tripple{
      display: flex;
      flex-direction: column;
      padding: 0.5em;
    
    }

    .tripple img {
      max-width: 100%;
      height:auto;
    }

    .mini-card {
      display: table;
      margin: auto;
      border: none;
      padding: 0;
    }
    .mini-card p {
      display: table-caption;
      caption-side: bottom;
    }

    .hero-text {
      font-size: small;
    }
  }


  @media only screen and (max-width: 1000px)and (min-width: 669px){

    .intro-section {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 1em;
    }

    .welcome {
      justify-content: center;
      align-content: center;
    }

    .card-section {
      display: flex;
      flex-direction: column;
    }
    .card-section img {
      max-width: 100%;
      height: auto;
      
    }
    .tripple {
      display: flex;
      flex-direction: column;
    }

    .mini-card {
      display: table;
      flex-direction: column;
      border: none;
      margin: auto;
      padding: 0.5em;
    }

    .tripple img {
      max-width: 100%;
      height: auto;
      margin: auto;
    }

    .mini-card p {
      display: table-caption;
      caption-side: bottom;
    }

  }

  @media only screen and (max-width: 1300px)and (min-width: 1001px){

    .card-section {
      display: flex;
      flex-direction: column;
      padding:0;
      width: auto;
      
    }
    .card-section img {
      width: 100%;
      height: auto;
      
      
    }
    .tripple {
      display: flex;
      flex-direction: column;
    }

    .mini-card {
      display: flex;
      flex-direction: column;
      border: none;
      margin: 0;
      padding: 0.5em;
    }

    .tripple img {
      width: auto;
    }
  }

  @media only screen and (max-width: 1568px)and (min-width: 1301px){

    .tripple {
      padding: 2em;
      display: flex;
      flex-direction: column;
    }

    .tripple img {
      width: auto;
    }
    .mini-card {
      display: flex;
      flex-direction: column;
      border: none;
      margin: 0;
      padding: 0.5em;
    }
  }


  @media only screen and (max-width: 1860px) and (min-width: 1501px)

  {
    .tripple {
      padding: 0;
    }

    .mini-card {
      flex: 1;
      border: none;
      margin-left: 0.5em;
      padding: 0;
    }
  }


  /* @media only screen and (max-width: 1900px) and (min-width: 1701px)

  {
    .tripple {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .card-section {
      align-items: center;
      justify-content: center;
    }

    .mini-card {
      display: flex;
      flex-direction: column;
      border: none;
      margin: 0;
      
      padding: 0.5em;
      border: 5px solid rgba(0, 0, 0, 0.116);
      align-items: center;
    }

    .mini-card button {
      max-width: 100px;
    }

    .mini-card p {
      max-width: 80%;
    }

    .tripple img {
      max-width: 100%;
      height:auto
    }

    .card-section {
      display: flex;
      flex-direction: column;
    }
    .card-section img {
      max-width: 100%;
      height: auto;
      
    }

  } */



  
  