h1, h2, h3, h4, h5, h6 {
  font-family: 'Rochester', cursive;
  font-weight: bold;
}

p, a, button {
  font-family: 'Poppins', sans-serif;
}

a {
  font-family: 'Poppins', sans-serif;
}

select, option {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  width: 100%;
  background-color: #f3eee7;
}

.pageWrapper{
  
  margin: 0;
  padding: 0;
}
