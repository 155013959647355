@media (min-width: 1501px){

.surroundings-wrapper{
    width: 70%;
    margin: auto;
    text-align: center;
    gap: 10em;
    margin-bottom: 10em;
}


.imageGalleryAndHeading {
    
    margin: 2em;
    align-items: center;
    padding:1em;
    gap: 1em;
}

.imageGalleryAndHeading img {
    max-height: 80vh;
    width: auto!important;
}

.carousel img {
    width: auto;
}

.slide img {
    width: auto!;
}

}

@media (max-width: 1500px){
   
    .surroundings-wrapper{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-bottom: 1em;
        text-align: center;
    }

    .imageGalleryAndHeading {
        text-align: center;
    }

    
    .imageGalleryAndHeading {
        
        margin: 1em 0;
        align-items: center;
        padding:0.5em;
        gap: 1em;
    }

    .imageGalleryAndHeading img {
        display: block;
        max-height: 50vh;
        width: auto!;
    }
    
    .carousel img {
        width: auto;
    }
    
    .slide img {
        width: auto!;
    }

    .imageGalleryAndHeading button {
        display: none;
    }
}