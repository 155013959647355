footer {
    background-color: #000000;
    width: 100%;
    color: white;
    margin-top: 3em;
    padding: 3em 0;
   
}

section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
}
.info{
    margin-top: 1em;
    line-height: 3px;
}

.social{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icons{
    display: flex;
    gap: 30px;
}

.link {
    text-align: center;
    padding: 1em;
}

@media screen and (max-width: 768px){
    section {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .info {
        text-align: center;
    }
}