.hero-div-butik {
    background-image: url(/public/assets/butik-small.webp);
    height: 90vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
}

.hero-text {
    width: 100%;
    background-color: rgba(29, 29, 29, 0.747);
    color: #f3eee7;
    text-align: center;
    font-size: x-large;
}

.butik-wrapper {
    width: 60%;
    margin: auto;
    padding-top: 5em;
}

.flex {
    display: flex;
    gap: 2em;
}
.shoplist {
    display: flex;
    flex-direction: column;
    align-items: start;
    list-style: unset;
}

.bg-color {
    background-color:rgba(92, 88, 88, 0.116); 
    padding: 2em;
    margin-top: 2em;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2)
}

.fat {
    font-weight: bold;
    padding: 1em 0;
}



@media screen and (max-width: 1200px){
    .hero-text {
        width: 100%;
        background-color: rgba(29, 29, 29, 0.747);
        color: #f3eee7;
        text-align: center;
        font-size: small;
    }

    .butik-wrapper{
        width: 90%; 
        padding-top: 1em;
    }

    .flex {
        display: flex;
        flex-direction: column;
    }

    .flex img {
        max-width: 60%;
        justify-self: center;
        align-self: center;
    }


}