.header {
    
    width: 100%;
    
    background-color: #f3eee7;
    border-bottom: solid rgba(0, 0, 0, 0.116);
    margin-bottom: -2px;
    position: sticky;
    top: 0;
    z-index: 5;
   }
   
   .nav {
    width: 75%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
   }

   .nav__list__web {
    display: flex;
    flex-direction: row;
    gap: 2em;
   }

 
   select {
    background-color: #f3eee7;
    border: none;
    font-size: 15px;
}
   ul a:hover{
    border-bottom: solid rgba(0, 0, 0, 0.116);
}

   .nav__toggle,
   .nav__close {
       font-size: 1.5rem;
       color: var(--title-color);
       cursor: pointer;
   }

   .nav__menu {
    right: -110%;
   }

   .nav__list {
    display: flex;
    flex-direction: row;
    row-gap: 2.5rem;
   }

   .nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
   }
   
   .show-menu {
    right: 0;
   }

   @media screen and (max-width: 1150px) {
    .nav__menu {
      position: fixed;
      top: 0;
      right: -110%;
      background-color: #f3eee7f1;
      width: 80%;
      height: 100%;
      padding: 6rem 1rem 0;
      transition: right 0.4s;
    }

    .nav {
        width: 90%;
    }

   
   }

   @media screen and (max-width: 300px) {
    .nav__menu {
      padding: 4rem 1rem 2rem;
      right: -110%;
      padding: 6rem 1rem 0;
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
    }
  
    .nav__list {
      row-gap: 1rem;
    }
  
    .nav__link,
    .nav__cta {
      font-size: var(--smaller-font-size);
    }
  
    .nav__cta {
      padding: 8px 16px;
      font-size: 12px;
      margin-top: 1rem;
      width: auto;
      white-space: nowrap;
    }
  }
  
  .nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
  }
  
  .nav__link {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
    transition: color 0.4s;
  }
  
  .nav__link:hover {
    color: var(--first-color);
  }
  
  .nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
  
  .show-menu {
    right: 0;
  }
  .nav__listt {
    display: flex;
    flex-direction: row;
    column-gap: 2.5rem;
  }
  
  .nav__menu_mobile {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(0, 0%, 6%, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
  }

   @media screen and (min-width: 1150px) {
    .nav {
      height: calc(var(--header-height) + 1rem);
    }
    .nav__toggle,
    .nav__close {
      display: none;
    }
   
    .nav__list {
      flex-direction: row;
      column-gap: 2.5rem;
    }
   }