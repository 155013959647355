.book-wrapper {
    width: 70%;
    margin: auto;
}

.book-div {
    display: flex;
    justify-content: space-between;
    
}

.single {
    background-color: rgba(92, 88, 88, 0.116);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 3em;
    margin: 1em;
}

a {
    text-decoration: none;
    color: black
}

@media screen and (max-width: 965px){
    .book-wrapper {
        width: 90%;
        margin: auto;
    }

    .book-wrapper p{
      max-width: 80%;
    }

    .book-div{
        display: flex;
        flex-direction: column;
        
    }

    .single {
        padding: 1em;
        max-width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0;
        margin-bottom: 1em;
    }

    .book-div img {
        width: 90%;
        height: auto;
        margin: auto;
    }

}


@media only screen and (max-width: 1550px)and (min-width: 966px){
    .book-div{
        display: flex;
        flex-direction: column;
        gap: 2em;
    }

    .single {
        padding: 1em;
        width: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    .single img {
        
        width: auto;
        margin: auto;  
    }

    .single a {
        max-width: 70%;
        margin: auto;
    }

    .single h2 {
        text-align: center;
    }
}